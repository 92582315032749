import Cookies from "js-cookie"
import instance from "./instance"

export const loginApi = async(email:string,password:string)=>{
    try{
        const get:any = await instance.post('/login',{
            email,
            password
        })
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return {status:e.status, message:e.data.message}
    }
}
export const RegisterApi = async(email:string,password:string,name:string,password_confirmation:string)=>{
    try{
        const get:any = await instance.post('/register',{
            name,
            email,
            password,
            password_confirmation
        })
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}
export const LogoutApi = async()=>{
    try{
        const get:any = await instance.post('/logout')
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}