import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createBrowserHistory } from 'history';

const instance = axios.create({
  // baseURL: 'https://stu-war-server.vercel.app/api',
  baseURL: "https://todoserver.studiorinternational.in/api",
  // baseURL: "http://192.168.1.39:7000/api",
});
const token = Cookies.get("token");
instance.defaults.headers.common["Authorization"] = 'Bearer ' + token;
instance.defaults.headers.common["Accept"] = "application/json";


instance.interceptors.response.use(
  response => {
    // Return response if it's successful
    return response;
  },
  error => {
    // console.log(error.response.data.message);
    // If the error status is 401, redirect the user to the login page
    if (error.response.status === 401) {
      Cookies.remove("token")
      Cookies.remove('profile')
      Cookies.remove('user')
      window.location.href = '/?message=' + error.response.data.message;
    }
    if (error.response.status === 403) {
      // window.location.href = '/?message=' + error.response.data.message;
      console.log(error.response.data.message);
      toast.error(error.response.data.message)
    }
    if(error.response.status === 422){
        if(typeof(error.response.data.message) === "object"){
          Object.values(error.response.data.message).map((errorMessages:any, index:any) => {
            errorMessages.map((errorMessage:any, innerIndex:any) => {
              toast.error(errorMessage)
            })
          })
        }else{
          toast.error(error.response.data.message)
        }
      return false;
    }
    // Return the error
  }
);

export default instance;
