import instance from "./instance"


export const createDirectory = async(name:string)=>{
    try{
        const get:any = await instance.post('/directory',{
            name,
        })
        return get.data;
    }catch(e:any){
        return false
    }
} 
export const getDirectory = async()=>{
    try{
        const get:any = await instance.get('/directory')
        return get.data;
    }catch(e:any){
        return false
    }
}

export const deleteDirectory = async(id:number)=>{
    try{
        const get:any = await instance.delete(`directory/${id}`)
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}