import React, { useEffect, useState } from 'react'
import Models from './Models'
import { checkbox, inputError, inputField } from '../../helpers/Classes'
import { FaPlus  } from "react-icons/fa";
import { getDirectory } from '../../services/directory';
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form"
import { taskCreateSchema } from '../../helpers/schemaValidation';
import { createTask } from '../../services/task';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {addTaskStatus} from '../../redux/AddtaskSlice';
import { generateRandomString } from '../../helpers/commonHelper';
export default function AddTask(props:{
    isOpen:any;
    isClose:any
}) {
    const [modelStatus, setModelStatus] = useState<any>()
    const [directories, setDirectories] = useState<any>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dirAdd = useSelector((state:any) => state.AddDirectorySlice)
    const {
      register,
      reset,
      handleSubmit, 
      formState: { errors },
    } = useForm({
      resolver: yupResolver(taskCreateSchema),
      
    })
    const onSubmit = handleSubmit(async(data:any) => {
      const create:any = await createTask(data.directory_id,data.title,data.is_important,data.is_complete);
        if(create?.status === 'success'){
          toast.success(create?.message);
          reset();
          setModelStatus(false)
          dispatch(addTaskStatus(generateRandomString(8)))
          // navigate('/admin/all-tasks')
        }
    })

    const fetchDirectory = async()=>{
      const get = await getDirectory();
      if(get?.status === 'success'){
          setDirectories(get?.message);
      }
  }
  useEffect(()=>{
      fetchDirectory();
  },[dirAdd])


  return (
    <Models name="Create New Task" isOpen={props.isOpen} isClose={props.isClose} close={modelStatus}  >
       <div>
          <div>
              <form onSubmit={onSubmit} className='p-2 '>
                <div className="flex flex-col mb-3">
                  <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Title <sup>*</sup></label>
                  <input type="text"  className={`${inputField} ${errors.title ? inputError : null} `} {...register("title")} placeholder='title'   />
                  {
                    errors.title ? (<small className='text-red-800 ml-2'>{errors.title.message}</small>) :null
                    }
                </div>
                {/* <div className="flex flex-col mb-3">
                  <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Date <sup>*</sup></label>
                  <input type="date"  className={`${inputField} ${errors.name ? inputError : null} `}  placeholder='title' required  />
                </div> */}
                <div className="flex flex-col mb-3">
                  <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Select Directory <sup>*</sup></label>
                  <select  className={`${inputField} ${errors.directory_id ? inputError : null} `}  {...register("directory_id")}   id="">
                    <option value="">Select directory</option>
                    {
                      directories.length > 0 ? (
                       directories.map((e:any,index:number)=>(
                        <option key={index} value={e.id}>{e.name}</option>
                       ))
                      ) :null
                    }
                  </select>
                  {
                    errors.directory_id ? (<small className='text-red-800 ml-2'>{errors.directory_id.message}</small>) :null
                    }
                </div>
                <div className="flex flex-col mb-3">
                  <div className='flex items-center justify-start gap-2 ml-2'>
                  <input {...register("is_important")} type="checkbox"  value={1} className={`${checkbox} `}  /> Mark as important
                  </div>
                  <div className='flex items-center justify-start gap-2 ml-2'>
                  <input  {...register("is_complete")} type="checkbox"  value={1}  className={`${checkbox} `}  /> Mark as completed
                  </div>
                </div>
                  <button className='bg-gray-100 shadow-md hover:shadow-xl rounded-lg my-2 text-white bg-gradient w-full p-2'>Add Task</button>
              </form>
          </div>
       </div>
      </Models>
  )
}
