import React, { useState } from 'react'
import { inputError, inputField } from '../../helpers/Classes';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form"
import { registerSchema } from '../../helpers/schemaValidation';
import { yupResolver } from "@hookform/resolvers/yup"
import { RegisterApi } from '../../services/authService';
import { toast } from 'react-toastify';

export default function Register() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerSchema),
  })
  const onSubmit = handleSubmit(async(data:any) => {
    setLoading(true)
    const register:any= await RegisterApi(data.email,data.password,data.name,data.password_confirmation);
      if(register?.status === 'success'){
          toast.success(register?.message)
          navigate('/')
      }else{
        toast.error(register?.message)
        setLoading(false)
      }
  })


  return (
    <section className='h-screen w-full bg-gray-200 dark:bg-primary text-dark dark:text-white flex flex-col items-center justify-center'>
    <div className=' dark:bg-white h-50 bg-gray-400 p-5 rounded-xl shadow-xl w-[70%] sm:w-[95%] md:w-[50%] lg:w-[30%]'>
    <p className='font-bold text-xl'>New here?</p>
    <p className='font-semibold text-lg text-gray-700'> Signing up is easy. It only takes a few steps</p>
    <form onSubmit={onSubmit} className='mt-10 mb-20'>
    <div className="flex flex-col mb-3">
         <label className='text-gray-600 dark:text-gray-400 ml-1 ' >name <sup>*</sup></label>
         <input type="text" className={`${inputField} ${errors.name ? inputError : null} `} {...register("name")} placeholder='name'   />
         {
          errors.name ? (<small className='text-red-800 ml-2'>{errors.name.message}</small>) :null
         }
       </div>
       <div className="flex flex-col mb-3">
         <label className='text-gray-600 dark:text-gray-400 ml-1 ' >email <sup>*</sup></label>
         <input type="email" className={`${inputField} ${errors.name ? inputError : null} `} {...register("email")}   placeholder='email'   />
         {
          errors.email ? (<small className='text-red-800 ml-2'>{errors.email.message}</small>) :null
         }
       </div>
       <div className="flex flex-col mb-3">
         <label className='text-gray-600 dark:text-gray-400 ml-1 ' >password <sup>*</sup></label>
         <input type="password" className={`${inputField} ${errors.name ? inputError : null} `}  {...register("password")}    placeholder='password'   />
         {
          errors.password ? (<small className='text-red-800 ml-2'>{errors.password.message}</small>) :null
         }
       </div>
       <div className="flex flex-col mb-3">
         <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Confirm password <sup>*</sup></label>
         <input type="password" className={`${inputField} ${errors.name ? inputError : null} `}   {...register("password_confirmation")}   placeholder='confirm password'   />
         {
          errors.password_confirmation ? (<small className='text-red-800 ml-2'>{errors.password_confirmation.message}</small>) :null
         }
       </div>
       <button disabled={loading ? true : false} className='bg-gray-100 shadow-md hover:shadow-xl rounded-lg my-2 text-white bg-gradient w-full p-2'>
        {
          loading ? 'logging...' : 'SIGN UP'
        }
       </button>
    </form>
    <div className='text-center'>
    <button onClick={(()=>navigate('/'))} className='hover:bg-secondary hover:text-white rounded-lg px-4 py-1 transition' >Already have an account? <span className=' font-semibold'>Sign In</span> </button>
    </div>
   </div>
   </section>
  )
}




