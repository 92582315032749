import { configureStore } from '@reduxjs/toolkit';
import breadcrumSlice from './breadcrumSlice';
import commonSlice from './commonSlice';
import mobileSideBar from './mobileSideBar';
import themeSlice from './themeSlice';
import AskLocationSlice from './AskLocationSlice';
import AuthSlice from './AuthSlice';
import AddtaskSlice from './AddtaskSlice';
import AddDirectorySlice from './AddDirectorySlice';

const store = configureStore({
    reducer: {
        breadcrumSlice: breadcrumSlice,
        commonSlice:commonSlice,
        mobileSideBar:mobileSideBar,
        themeSlice:themeSlice,
        AskLocationSlice:AskLocationSlice,
        AuthSlice:AuthSlice,
        AddtaskSlice:AddtaskSlice,
        AddDirectorySlice:AddDirectorySlice
    },
});

export default store;