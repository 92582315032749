import * as Yup from 'yup';

export const registerSchema = Yup.object({
    name:Yup.string().min(3).required(),
    email:Yup.string().email().required(),
    password:Yup.string().min(6).required(),
    password_confirmation:Yup.string().oneOf([Yup.ref('password')],"password does not match").required()
});

export const loginSchema = Yup.object({
    email:Yup.string().email().required(),
    password:Yup.string().min(6).required(),
})
export const directoryCreateSchema = Yup.object({
    name:Yup.string().min(2).required(),
})

export const taskCreateSchema = Yup.object({
    title:Yup.string().min(2).required(),
    directory_id:Yup.number().required(),
    is_important:Yup.boolean(),
    is_complete:Yup.boolean(),
})

