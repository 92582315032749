import React, { Suspense, lazy, useEffect, useState } from 'react'
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Admin from "./pages/admin/Admin";
import Directory from './pages/admin/Directory';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import AllTask from './pages/admin/AllTask';
import ImportantTask from './pages/admin/ImportantTask';
import PendingTask from './pages/admin/PendingTask';
import CompletedTask from './pages/admin/CompletedTask';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import { useSelector } from 'react-redux';

function App() {
  const themeSlice = useSelector((state:any) => state.themeSlice)
  const isLogin = useSelector((state: any) => state.AuthSlice.isLoggedIn); 
    const Dashboard = lazy(() => import("./pages/admin/Dashboard"));
    const AllTask = lazy(() => import("./pages/admin/AllTask"));
    const ImportantTask = lazy(() => import("./pages/admin/ImportantTask"));
    const PendingTask = lazy(() => import("./pages/admin/PendingTask"));
    const CompletedTask = lazy(() => import("./pages/admin/CompletedTask"));
    const Directory = lazy(() => import("./pages/admin/Directory"));
    const queryClient = new QueryClient()
  //   const navigate = useNavigate();
  // if(!isLogin){
  //   navigate('/?message=login required')
  // }
  return (
    <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ToastContainer 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={themeSlice}
      />
      <Suspense>
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register /> } />
      <Route path="/admin" element={isLogin ? <Admin /> : <Login />}>
      <Route index element={<Dashboard />} />
      
         <Route path="dashboard" element={ <Dashboard /> } />
         <Route path="all-tasks" element={ <AllTask /> } />
         <Route path="important-tasks" element={<ImportantTask /> } />
         <Route path="pending-tasks" element={ <PendingTask /> } />
         <Route path="completed-tasks" element={ <CompletedTask /> } />
         <Route path="directory" element={ <Directory /> } />
    </Route>
      </Routes>
      </Suspense>
    </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
