import instance from "./instance"


export const getAlltasks = async()=>{
    try{
        const get:any = await instance.get('tasks')
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}
export const createTask = async(directory_id:number,title:string,is_important:boolean,is_complete:boolean)=>{
    try{
        const get:any = await instance.post('tasks',{
            directory_id,
            title,
            is_important,
            is_complete,
        })
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}
export const deleteTask = async(id:number)=>{
    try{
        const get:any = await instance.delete(`tasks/${id}`)
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}
export const updateTaskStatus = async(id:number,is_important:any)=>{
    try{
        const get:any = await instance.put(`tasks/status/${id}`,{
            is_important
        })
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}
export const updateTaskComplete = async(id:number,is_complete:any)=>{
    try{
        const get:any = await instance.put(`tasks/complete/${id}`,{
            is_complete
        })
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}

export const getImportanttasks = async()=>{
    try{
        const get:any = await instance.get('tasks/important')
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}
export const getPendingtasks = async()=>{
    try{
        const get:any = await instance.get('tasks/pending')
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}
export const getCompletetasks = async()=>{
    try{
        const get:any = await instance.get('tasks/complete')
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}
export const getTasksInfo = async(date:any)=>{
    try{
        const get:any = await instance.get(`tasks/task_info?date=${date}`)
        return {status:get?.data?.status, message:get?.data?.message,stats:get?.data?.stats}
    }catch(e:any){
        return false;
    }
}
export const getpendingTaskStatus = async()=>{
    try{
        const get:any = await instance.get(`tasks/pending_task-status`)
        return {status:get?.data?.status, message:get?.data?.message}
    }catch(e:any){
        return false;
    }
}