import React, { useEffect, useState } from 'react'
import { inputError, inputField } from '../../helpers/Classes';
import { useNavigate,useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup"
import { RegisterApi, loginApi } from '../../services/authService';
import { useForm } from "react-hook-form"
import { loginSchema } from '../../helpers/schemaValidation';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/AuthSlice';
import { setAuthToken } from '../../services/AuthConfig';
import logo from '../../assets/logomain.png';
export default function Login() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  let getToken:any = Cookies.get('token');
   
  useEffect(() =>{
    const message = new URLSearchParams(location.search).get('message');
    if(message){
      toast.error(message);
    }
    console.log(getToken)
    if(getToken !== undefined || getToken !== null || getToken !== ""){
          navigate('/admin/dashboard');
    }
  },[]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  })
  const onSubmit = handleSubmit(async(data:any) => {
    setloading(true)
    const register:any= await loginApi(data.email,data.password);
    // console.log(register)
      if(register?.status === 'success'){
        setAuthToken(register?.message?.token)
         Cookies.set("token",register?.message?.token);
         Cookies.set("profile",register?.message?.profile);
         Cookies.set("user",JSON.stringify(register?.message?.user));
         dispatch(login());
         navigate("/admin/dashboard");
         
      }else{
        toast.error(register?.message)
        setloading(false);
      }
      
  })

 
  return (
    <section className='h-screen w-full bg-gray-200 dark:bg-primary text-dark dark:text-white flex flex-col items-center justify-center'>
  
    <div className=' dark:bg-white h-50  bg-gray-400 p-5 rounded-xl shadow-xl w-[70%] sm:w-[95%] md:w-[50%] lg:w-[30%]'>
      
    <p className='font-bold text-xl'>
   
          <img src={logo} alt="" />
    
      Hello! let's get started</p>
    <p className='font-semibold text-lg text-gray-700'> Sign in to continue.</p>
    <form onSubmit={onSubmit} className='my-20'>
       <div className="flex flex-col mb-3">
         <label className='text-gray-600 dark:text-gray-400 ml-1 ' >email <sup>*</sup></label>
         <input type="email" className={`${inputField} ${errors.email ? inputError : null} `}  {...register("email")} placeholder='email' required  />
         {
          errors.email ? (<small className='text-red-800 ml-2'>{errors.email.message}</small>) :null
         }
       </div>
       <div className="flex flex-col mb-3">
         <label className='text-gray-600 dark:text-gray-400 ml-1 ' >password <sup>*</sup></label>
         <input type="password" className={`${inputField} ${errors.password ? inputError : null} `} {...register("password")}  placeholder='password' required  />
         {
          errors.password ? (<small className='text-red-800 ml-2'>{errors.password.message}</small>) :null
         }
       </div>
       <button disabled={loading ? true : false} className='bg-gray-100 shadow-md hover:shadow-xl rounded-lg my-2 text-white bg-gradient w-full p-2'>
        {
          loading ? 'logging...' : 'SIGN IN'
        }
       </button>
    </form>
    <div className='text-center'>
    <button onClick={(()=>navigate('/register'))} className='hover:bg-secondary hover:text-white rounded-lg px-4 py-1 transition' >Don't have an account? <span className=' font-semibold'>Create Now</span> </button>
    </div>
   </div>
   </section>
  )
}
