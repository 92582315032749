import { createSlice } from '@reduxjs/toolkit';

let initialState:string = "initial";

const AddDirectorySlice:any = createSlice({
  name: 'AddDirectorySlice',
  initialState,
  reducers: {
    addDir: (state:any, action:any) => {
        return action.payload;
    },
  },
});

export const {addDir} =
AddDirectorySlice.actions;
export default AddDirectorySlice.reducer;

