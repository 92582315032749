import { createSlice } from '@reduxjs/toolkit';

let initialState:string = "initial";

const AddtaskSlice:any = createSlice({
  name: 'AddtaskSlice',
  initialState,
  reducers: {
    addTaskStatus: (state:any, action:any) => {
        return action.payload;
    },
  },
});

export const {addTaskStatus} =
AddtaskSlice.actions;
export default AddtaskSlice.reducer;

